import { SEO, Page as DefaultPage } from '~modules'
/* @TODO: fix alias, ~components not working */
import { components } from '../components'
import { fetchAllPageTitles, fetchConstantsMaps, fetchLostandFoundS3Content, fetchPageBySlug } from '../lib/hygraph'
import { REVALIDATION_INTERVAL, SLUG_HOMEPAGE } from '~constants'
import { getConstantsMapFromCms } from 'utils'
import { resolvePageBlocks } from 'utils/query/queryResolver'

const LOST_AND_FOUND_SLUG = '/lost-and-found'

interface StoryInterface {
  name: string
  content: object
}

interface PageProps {
  story: StoryInterface
  preview: boolean
}

export default function Page({ story, preview, lostandfoundData }: PageProps) {
  const { body } = story.content
  const { seo, title } = body

  return (
    <div>
      <SEO defaultTitle={title} {...seo} />
      <DefaultPage blok={story.content} components={components} preview={preview} lostandfoundData={lostandfoundData} />
    </div>
  )
}

export async function getStaticProps({ params, preview = false }) {
  let slug = params.slug[0] === '/' ? SLUG_HOMEPAGE : `/${params.slug.join('/')}`
  const data = await fetchPageBySlug(preview, { slug: slug })
  let lostandfoundData = null
  if (slug === LOST_AND_FOUND_SLUG) {
    lostandfoundData = await fetchLostandFoundS3Content()
  }
  const pages = await data.pages.map(async page => {
    const clonedPage = { ...page }
    clonedPage.blocks = await resolvePageBlocks(clonedPage)

    return clonedPage
  })

  const [allPages] = await Promise.all(pages)

  const constantsMapsArray = await fetchConstantsMaps(preview)
  const constantsMap = getConstantsMapFromCms(constantsMapsArray.constantMaps)

  return {
    props: {
      story: data ? { content: { body: { ...allPages, constantsMap } } } : false,
      lostandfoundData,
      ...(lostandfoundData && { lostandfoundData }),
      preview
    },
    revalidate: REVALIDATION_INTERVAL
  }
}

export async function getStaticPaths() {
  const { pages } = await fetchAllPageTitles(true)
  const publishedPagesSlug = pages.filter(page => page && page !== SLUG_HOMEPAGE).map(item => item.slug)

  // If you are working on a single page, then you can change the publishedPagesSlug to the slug you are working on.
  // ex: const publishedPagesSlug = '/faqs'

  return {
    paths: publishedPagesSlug,
    fallback: false
  }
}
